// Conditions

.node-type-condition,
.node-type-condition-sub-page {

  .pane-node-field-condition-image,
  .pane-node-field-condition-image-zoom {

    @include breakpoint($sm) {
      max-width: 210px;
      float: right;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    figure {
      position: relative;
      margin-bottom: 20px;

      a {
        position: relative;
        display: block;
      }

      &:hover {
        .cta-icon {
          color: $wcm-white;
          fill: $wcm-white;
        }
      }
    }

    img {
      // max-width: 210px !important;
      width: 100% !important;
      float: none !important;
      margin: 0 !important;
      margin-bottom: 0px !important;
    }

    .cta-icon {
      width: 30px;
      height: 30px;
      transition: all 0.3s ease 0s;
      position: absolute;
      bottom: 15px;
      right: 10px;
      fill: $wcm-bright-orange;
      color: $wcm-dark-orange;
    }

    figcaption {
      float: none;
      width: auto;
      text-align: left;
      padding-top: 5px;
    }
  }

  .pane-node-field-condition-related {
    margin-bottom: 20px;

    @include breakpoint($xs) {
      text-align: center;
    }

    .pane-title {
      @extend .section-header;
    }

    a {
      margin-right: 20px;
      margin-bottom: 10px;
      position: relative;
      display: inline-block;
      border: 1px solid transparent;
      border-radius: 20px;
      border-width: 4px;
      font-size: 13px;
      padding: 6px 40px;
      color: $wcm-dark-orange;
      background-color: transparent;
      border-color: $wcm-bright-orange;

      @include breakpoint($xs) {
        margin-right: 0;
        display: block;
      }

      &:before {
        position: absolute;
        height: 100%;
        font-size: 125%;
        color: #fff;
        transition: all .3s;
        content: "\e802";
        @include fontello();
        line-height: 2;
        left: 70%;
        opacity: 0;
        top: 0;
      }

      &:hover {
        color: $wcm-white;
        background-color: $wcm-dark-orange;
        border-color: $wcm-red;
        border-width: 4px;

        &:before {
          left: 86%;
          opacity: 1;
        }
      }
    }
  }

  .pane-node-field-podcast-embed {
    @include clearfix();

    .section-header {
      margin-top: 0;
    }
  }

  .pane-node-field-condition-credit {
    border-top: 1px solid $wcm-border-gray;
    padding-top: 20px;
  }
}

.pane-conditions-panel-pane-1 {
  margin-top: 20px;

  .view-conditions {
    .view-header {
      margin-bottom: 20px;

      label {
        font-size: 13px;
      }
    }

    .view-content {
      .view-group {
        border-top: 1px solid $wcm-border-gray;
        padding-top: 3rem;

        .group-title {
          font-size: 5rem;
          margin-top: 0;
          margin-bottom: 20px;
          color: $wcm-med-gray;
          border-bottom: 6px solid $wcm-bg-gray;

          @include breakpoint($sm) {
            float: left;
          }
        }

        .views-row {
          margin-bottom: 2rem;
          padding-bottom: 2rem;
          // border-bottom: 1px solid $wcm-border-gray;

          @include breakpoint($sm) {
            margin-left: 10rem;
          }

          .views-field-title {
            h3 {
              margin-top: 0;
            }
          }

          &:not(:last-child) {
            border-bottom: 1px solid $wcm-border-gray;
          }
        }
      }
    }
  }
}

.pane-conditions-panel-pane-2 {

  @include make-sm-column(4);

  .pane-title {
    @extend .section-header;
  }

  .views-row {
    @include breakpoint($xs) {
      text-align: center;
    }
  }
}

.pane-conditions-panel-pane-5 {
  clear: both;

  .pane-title {
    @extend .section-header;
    padding-bottom: 0;
  }

  .views-row {
    border-bottom: 1px solid $wcm-border-gray;
    padding-bottom: 20px;
  }
}

.pane-sub-pages-by-location {
  clear: both;

  .section-header {
    padding-bottom: 0;
  }

  .view-conditions,
  .view-programs {

    .views-row {
      border-bottom: 1px solid $wcm-border-gray;
      padding-bottom: 20px;
    }
  }

  .view-patient-stories {
    .section-header {
      padding-bottom: 20px;
    }
  }
}
