// Initiatives

.pane-initiatives-panel-pane-1 {

  @include clearfix();
  margin-left: -20px;
  margin-right: -20px;

  .view-initiatives {

    .view-content {
      display: flex;
      flex-wrap: wrap;
    }

    .views-row {
      @include make-sm-column(4);
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
    }
  }

  // .pane-title {
  //   @extend .section-header;
  // }

  // border-top: 1px solid $wcm-border-gray;
  // padding-top: 10px;
  // margin-top: 20px;

  // .views-row {
  //   border-bottom: none;
  // }

  // .views-field-title {
  //   padding-bottom: 10px;
  // }
}
