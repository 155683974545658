.front {
  .neurosurgery-main-content {
    // padding-top: 20px;

    // .panel-panel-inner {
    .pane-bundle-media-album {
      // padding-top: 10px;
      border-bottom: 1px solid $wcm-border-gray;
    }
  }

  .pane-upcoming-events {
    border-bottom: 1px solid $wcm-border-gray;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .news-section-header {
    h3 {
      @extend .section-header;
    }
  }

  .wcm-upcoming-events-section {
    margin-top: 0;
  }

  .wcm-upcoming-events-inner-section {
    padding-top: 0;
  }

  .neurosurgery-footer {
    padding-top: 40px;
  }
}
