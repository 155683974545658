@import "../../../../../profiles/wcmc/modules/custom/wcm_person/css/wcm_person.scss";

// Person page

.node-type-wcm-person {
  .btn-directory {
    @include breakpoint((max: 991px)) {
      margin-top: 20px;
    }
  }

  .main-content {
    @include clearfix();
  }

  .sidebar {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 20px;

    @include breakpoint($sm) {
      padding-right: 30px;
    }
  }

  .pane-locations-panel-pane-2 {
    .view-content {
      .views-row {
        border-top: 1px solid $wcm-border-gray;
        padding-top: 10px;
        margin-top: 10px;
      }
    }
  }

  .pane-news-panel-pane-5 {
    clear: both;
  }

  .pane-patient-stories-panel-pane-2 {
    .pane-title {
      @extend .section-header;
    }
  }

  .pane-node-field-wcm-person-title {
    .field-wcm-person-title {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }
    }
  }

  .pane-node-field-wcm-person-image {
    margin-bottom: 10px;

    img {
      border-top: 8px solid $wcm-red;
    }
  }

  .pane-node-field-wcm-person-title {
    font-size: 18px;
    font-weight: 700;
    color: $wcm-med-gray;
    padding-bottom: 20px;
  }

  .pane-node-field-wcm-person-research-url {
    display: inline-block;
    padding-right: 30px;
  }

  .pane-node-field-wcm-person-clinical-url {
    display: inline-block;
  }

  .pane-node-field-wcm-person-phone {
    margin-top: 5px;

    .field-label {
      display: inline-block;
      font-weight: 700;
    }
  }

  .pane-node-field-podcast-embed {
    @include clearfix();

    .section-header {
      margin-top: 0;
    }
  }
}

// Leadership pane
.pane-person-list-neurosurgery-panel-pane-1 {
  .view-content {
    .person-list-item {
      display: block;
      flex-direction: unset;

      .person-headshot {
        padding-left: 0;
        padding-right: 0;

        // .view-profile-details {
        //   border-top: 8px solid $wcm-red;
        // }

        @include breakpoint($sm) {
          width: calc(33.33333% - 20px);
        }
      }

      .views-field-nothing-1 {
        @include breakpoint($sm) {
          @include v-center();
        }

        padding-left: 0;
        padding-right: 0;

        .person-phone {
          padding-bottom: 10px;
        }

        .person-name-suffix {
          &:before {
            content: ', ';
            position: relative;
          }

          &:empty {
            &:before {
              content: '';
            }
          }
        }

        .item-list {
          padding-top: 10px;

          ul {
            list-style: none;

            li {
              margin: 0;
            }
          }
        }

        @include breakpoint($sm) {
          padding-left: 30px;
        }
      }
    }
  }
}

// Person list by Condition
.pane-person-list-neurosurgery-panel-pane-2,
.pane-person-list-neurosurgery-panel-pane-3,
.pane-person-list-neurosurgery-panel-pane-4,
.pane-person-list-neurosurgery-panel-pane-5 {
  // @include clearfix();
  clear: both;

  .pane-title {
    @extend .section-header;
  }
}

// Directory Page
.view-person-list-neurosurgery {
  // Apply the original SCSS class from the wcm_person module
  @extend .view-person-list;

  .view-content {
    h3 {
      margin-bottom: 1rem;
      width: 100%;
      padding-left: 2rem;
    }

    .person-headshot {
      .view-profile-detail {
        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }

      .bio-icon {
        svg {
          transition: all 0.3s ease 0s;
          width: 40px;
          height: 40px;
          position: absolute;
          bottom: 10px;
          right: 10px;
        }

        /*background: url(../../../../../profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/profile.svg) no-repeat;
        background-size: 40px;
        width: 40px;
        height: 40px;
        position: absolute;
        bottom: 10px;
        right: 10px;*/
      }

      &:hover {
        .bio-icon svg path {
          fill: $wcm-white;
        }
      }
    }
  }
}
