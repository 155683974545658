// Blog
.node-type-blog-item {
  .pane-node-field-blog-item-date {
    text-transform: uppercase;
    padding: 5px 0px 10px;
    color: $wcm-med-gray;
    // font-family: $wcm-bold;
    font-weight: 700;
    letter-spacing: 2px;
    font-size: 13px;
  }

  .pane-node-field-featured-image {
    @include breakpoint($md) {
      width: 33%;
      float: right;
      margin-left: 2rem;
      margin-bottom: 2rem;
    }
  }
}

.pane-blog-panel-pane-2 {
  border-top: 1px solid $wcm-border-gray;
  padding-top: 10px;
  margin-top: 20px;

  .section-header {
    padding-bottom: 0;
  }

  .view-content {
    .views-row {
      border-bottom: none;
    }
  }

  .views-field-title {
    padding-bottom: 10px;
  }
}

.pane-blog-panel-pane-4,
.pane-blog-panel-pane-5 {
  border-top: 1px solid $wcm-border-gray;

  .section-header {
    padding-bottom: 0;
  }

  .view-blog {
    .view-content {
      .views-row {
        border-bottom: none;
      }
    }
  }
}

.view-blog {
  .view-content {
    .views-row {
      @include clearfix();
      margin-top: 2rem;
      padding-bottom: 2rem;
      border-bottom: 1px solid $wcm-border-gray;

      .views-field-field-featured-image {
        margin: 1rem 0;

        img {
          float: none;
          max-width: none;
          margin: 0;
          width: 100%;

          @include breakpoint($sm) {
            width: 25%;
            max-width: 25%;
            float: right;
            margin: 0 0 1rem 1rem;
          }
        }
      }

      .views-field-field-blog-item-date {
        text-transform: uppercase;
        padding: 0px 0px 10px;
        color: $wcm-med-gray;
        font-family: $wcm-bold;
        letter-spacing: 2px;
        font-size: 13px;
      }

      .views-field-field-blog-item-authors {
        padding-bottom: 5px;

        .views-label {
          font-weight: bold;
        }

        .field-content {
          display: inline;
        }
      }

      // .views-field-body {
      //   overflow: hidden;
      // }
    }
  }
}

.pane-blog-panel-pane-3 {
  clear: both;
  border-top: 1px solid $wcm-border-gray;

  .section-header {
    padding-bottom: 0;
  }
}

.pane-patient-stories-panel-pane-3 {
  clear: both;
}

.pane-blog-panel-pane-6 {
  .pane-title {
    @extend .section-header;
  }

  .view-blog {
    background: $wcm-bg-gray;
    padding: 20px;

    .view-content {
      .views-row-first {
        padding-bottom: 20px;
      }

      .views-row-last {
        border-bottom: none;
      }
    }
  }
}
