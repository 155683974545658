// Initiatives
.node-type-location {
  .pane-node-body {
    border-top: 1px solid $wcm-border-gray;
    padding-top: 15px;
  }

  .pane-node-field-location-address {
    margin-bottom: 15px;

    /*@include breakpoint($sm) {
      width: 50%;
      display: inline-block;
    }*/
  }

  .pane-node-field-location-phone {
    display: inline-block;
    margin-bottom: 15px;
  }

  .pane-node-field-location-fax {
    margin-bottom: 15px;

    @include breakpoint($sm) {
      display: inline-block;
    }
  }

  .pane-node-field-location-phone+.pane-node-field-location-fax {
    @include breakpoint($sm) {
      border-left: 1px solid $wcm-border-gray;
      padding-left: 15px;
      margin-left: 15px;
    }
  }
}

.pane-location-map {
  margin-bottom: 30px;
}

.pane-locations-panel-pane-1 {

  @include clearfix();
  margin-left: -20px;
  margin-right: -20px;

  .view-locations {
    h3 {
      @extend .section-header;
      padding-left: 20px;
      width: 100%;
    }

    .view-content {
      display: flex;
      flex-wrap: wrap;
    }

    .views-row {
      @include make-sm-column(6);
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
    }
  }
}
