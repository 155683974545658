// Blog
.node-type-service {

  .pane-node-field-podcast-embed {
    @include clearfix();

    .section-header {
      margin-top: 0;
    }
  }

}

.pane-services-panel-pane-1 {
  .view-content {
    .views-row {
      @include clearfix();

      border-bottom: 1px solid $wcm-border-gray;
      padding-bottom: 10px;
      margin-top: 20px;

      .views-field-field-featured-image {
        @include breakpoint($xs) {
          margin-bottom: 10px;
        }

        img {
          float: none;
          max-width: none;
          margin: 0;
          width: 100%;

          @include breakpoint($sm) {
            width: 25%;
            max-width: 25%;
            float: right;
            margin-left: 20px;
          }
        }
      }

      .views-field-title {
        h3 {
          margin-top: 0;
        }
      }
    }
  }
}

.pane-services-panel-pane-2 {

  @include make-sm-column(4);

  .pane-title {
    @extend .section-header;
  }

  .views-row {
    @include breakpoint($xs) {
      text-align: center;
    }
  }
}

.pane-services-panel-pane-3 {
  clear: both;

  .pane-title {
    @extend .section-header;
    padding-bottom: 0;
  }

  .views-row {
    border-bottom: 1px solid $wcm-border-gray;
    padding-bottom: 20px;
  }

}
