// Programs
.node-type-program {
  .pane-node-field-podcast-embed {
    @include clearfix();

    .section-header {
      margin-top: 0;
    }
  }
}

.pane-programs-panel-pane-1 {
  h3 {
    font-size: 30px;
    margin-top: 40px;
  }

  .views-row {
    border-bottom: 1px solid $wcm-border-gray;
    padding-bottom: 20px;

    .patient-care-link {
      font-size: 20px;
      margin-top: 20px;
    }
  }
}

.pane-programs-panel-pane-2 {

  @include make-sm-column(4);

  .pane-title {
    @extend .section-header;
  }

  .views-row {
    @include breakpoint($xs) {
      text-align: center;
    }
  }
}

.pane-programs-panel-pane-4 {
  clear: both;

  .pane-title {
    @extend .section-header;
    padding-bottom: 0;
  }

  .views-row {
    border-bottom: 1px solid $wcm-border-gray;
    padding-bottom: 20px;
  }
}
