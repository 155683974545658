// News Posts on homepage
.view-news {
  .view-content {
    .views-row {
      margin-top: 2rem;
      padding-bottom: 2rem;
      border-bottom: 1px solid $wcm-border-gray;

      .teaser-image {
        img {
          float: none;
          max-width: none;
          margin: 0;
          width: 100%;

          @include breakpoint($sm) {
            width: 25%;
            max-width: 25%;
            float: right;
            margin: 0 0 1rem 1rem;
          }
        }
      }

      .post-date {
        text-transform: uppercase;
        padding: 5px 0px 0px;
        color: $wcm-med-gray;
        font-weight: 700;
        // font-family: $wcm-bold;
        letter-spacing: 2px;
        font-size: 1.3rem;
      }

      // .views-field-body {
      //   overflow: hidden;
      // }
    }
  }
}

.pane-news-panel-pane-5,
.pane-news-panel-pane-6,
.pane-news-panel-pane-7,
.pane-news-panel-pane-8 {
  .view-news {
    .view-content {
      .views-row {

        margin-top: 1rem;
        padding-bottom: 0;
        border: none;

        .teaser-image {
          img {
            @include breakpoint($sm) {
              float: left;
              margin: 0 3rem 1rem 0rem;
            }
          }
        }
      }
    }
  }
}

.pane-news-panel-pane-5,
.pane-news-panel-pane-6,
.pane-news-panel-pane-7,
.pane-news-panel-pane-8 {
  .section-header {
    padding-bottom: 10px;
  }
}

.pane-news-panel-pane-7 {
  border-top: 1px solid $wcm-border-gray;
}

// homepage news
.pane-news-panel-pane-9 {
  @include clearfix();
  margin-bottom: 20px;

  .view-news {
    .view-header {
      .section-header {
        padding-bottom: 0;
      }
    }

    .view-content {
      margin-left: -20px;
      margin-right: -20px;

      .views-row {
        border-bottom: none;

        .teaser-image {
          margin-bottom: 10px;

          img {
            float: none;
            width: 100%;
            max-width: none;
            margin: 0;
          }
        }
      }
    }
  }
}
