.block-menu-block {
  @include breakpoint($md) {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    -ms-column-count: 3;
    -o-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    -ms-column-gap: 15px;
    -o-column-gap: 15px;
    column-gap: 15px;
    margin-bottom: 1em;
  }
}

.btn--wcm {
  padding: 10px 20px;

  &:before {
    line-height: 2.5;
  }
}

.breadcrumb-nav .open .dropdown-menu {
  // transform-origin: top;
  max-height: 500px;
  overflow: auto;
}

.views-exposed-form label {
  font-size: 13px;
}

.section-header {
  text-transform: uppercase;
  color: $wcm-med-gray;
  font-family: $font-family-sans-serif;
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 20px;
  letter-spacing: 0.7px;

  @include breakpoint($xs) {
    text-align: center;
  }

  a {
    color: $wcm-med-gray;
  }
}

.pane-node-field-podcast-embed {
  clear: both;
}

.wcm-cta {
  .wcm-cta__tile {
    &:nth-child(5) {
      border-width: 1px 1px 0 0;

      &:hover {
        border-width: 1px;
      }
    }

    &:nth-child(6) {
      border-width: 1px 0 0;

      &:hover {
        border-width: 1px;
      }
    }

    /*&:nth-child(5) {
      width: 100%;
      border-width: 1px 0 0;
    }*/
  }
}

body.with-sub-menu {
  .main-content {
    @include breakpoint($sm) {
      padding-left: 280px;
    }
  }

  .pane-neurosurgery-sub-page-menu {
    @include breakpoint($sm) {
      position: absolute;
      width: 260px;
      left: 20px;
    }
  }

  .pane-node-field-media-video {
    .wcm-pgraph-video-playlist-wrapper {
      display: flex;
      flex-direction: column;
    }

    .wcm-video-playlist__title {
      order: 1;
    }

    .wcm-video-playlist__description {
      order: 2;
    }

    .wcm-video-playlist__player {
      order: 3;
    }

    .ycp {
      height: 1000px;
    }

    .ycp .belah:first-of-type {
      width: 100%;
      height: 40%;
    }

    .ycp .belah:last-of-type {
      width: 100%;
      height: 55%;
    }
  }
}

/*#main-content.has-sub-nav {
  @include breakpoint($sm) {
    padding-left: 280px;
  }
}

.pane-neurosurgery-sub-page-menu {
  @include breakpoint($sm) {
    position: absolute;
    width: 260px;
    left: 20px;
  }
}*/

// .main-content~.pane-neurosurgery-sub-page-menu {
//   padding-left: 290px;
// }

// .pane-neurosurgery-sub-page-menu {
//   .main-content & {
//     padding-left: 290px;
//   }
// }
//   .pane-neurosurgery-sub-page-menu {
//     @include breakpoint($sm) {
//       position: absolute;
//       width: 290px;
//     }
//   }
// }

// Sub page menu
.pane-menu-block-2,
.sub-page-menu {
  padding-bottom: 20px;

  @include breakpoint($sm) {
    width: 260px;
    float: left;
    padding-right: 30px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 600;

    li {
      a {
        display: block;
        border: none;
        padding: 10px 20px 10px 0;
        position: relative;

        &:after {
          content: '\e802';
          @include fontello();
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(-50%, -50%);
        }

        &.active {
          color: $wcm-black;
        }
      }

      ul {
        padding-left: 20px;

        li {
          a {
            border-top: 1px solid $wcm-border-gray;
          }

          &:last-child {
            a {
              border-bottom: 1px solid $wcm-border-gray;
            }
          }
        }
      }
    }
  }
}

.alpha-pager {
  padding-left: 0;

  .alpha-page {
    display: inline-block;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    font-weight: 600;
    border-right: 1px solid $wcm-border-gray;

    &.active {
      background-color: $wcm-border-gray;

      a {
        color: $wcm-black;
      }
    }
  }
}

.mailing-list-callout {
  margin-top: 30px;

  .wcm-pgraph-text-teaser__text {
    @include breakpoint($sm) {
      position: relative;
      padding-left: 180px;
    }

    &:before {
      @include breakpoint($sm) {
        content: '';
        background-image: url(../../../../../profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/email.svg);
        background-size: 65px;
        background-repeat: no-repeat;
        width: 105px;
        height: 65px;
        display: block;
        position: absolute;
        border-right: 1px solid $wcm-border-gray;
        left: 40px;
      }
    }
  }
}

.contact-callout {
  margin-top: 30px;

  .wcm-pgraph-text-teaser__text {
    @include breakpoint($sm) {
      position: relative;
      padding-left: 180px;
    }

    &:before {
      @include breakpoint($sm) {
        content: '';
        background-image: url(../../../../../profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/phone.svg);
        background-size: 65px;
        background-repeat: no-repeat;
        width: 105px;
        height: 65px;
        display: block;
        position: absolute;
        border-right: 1px solid $wcm-border-gray;
        left: 40px;
      }
    }
  }
}

.podcast-callout {
  background: $wcm-dark-orange;
  padding: 15px;

  .podcast-wrapper {
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 20px;

    span {
      display: block;
      padding-right: 40px;
      padding-left: 70px;

      &:first-of-type {
        font-size: 18px;
        font-weight: 600;

        &:after {
          content: '';
          display: block;
          transition: all 0.2s ease 0s;
          border-bottom: 1px solid rgba(221, 221, 221, 0.3);
          width: 0;
          margin-left: 50%;
        }
      }
    }
  }

  a {
    color: $wcm-white;
    display: block;
    border: none;
    position: relative;

    &:hover {
      span:first-of-type {
        &:after {
          width: 95%;
          margin-left: 0%;
        }
      }
    }

    &:before {
      content: '';
      background-image: url(../images/icon-podcast.svg);
      background-size: 45px;
      background-repeat: no-repeat;
      width: 45px;
      height: 60px;
      display: block;
      position: absolute;
      // border-right: 1px solid $wcm-border-gray;
      // position: absolute;
      // right: 30px;
      // height: 100%;
      // border-left: 1px solid rgba(255, 255, 255, 0.2);
    }

    &::after {
      content: '\e80d';
      @include fontello();
      color: $wcm-yellow;
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      align-items: center;
      display: flex;
      // top: 50%;
      // transform: translateY(-50%);
      padding-left: 20px;
      // height: calc(100% - 20px);
    }
  }

}

// Podcast field
.field-podcast-embed {
  margin: 30px 0;
  padding: 30px;
  background: $wcm-bg-gray;
}

// Link Button Callouts
.callout-link {
  margin: 30px 0;
  position: relative;
  display: flex;
  flex-direction: column;

  background-color: $wcm-dark-orange;
  color: $wcm-white;

  h3 {
    color: $wcm-white;
  }

  a {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-bottom: none;
    color: $wcm-white;

    .content {
      display: inline-flex;
      align-items: center;

      .field-title {
        &:after {
          content: '';
          display: block;
          transition: all 0.2s ease 0s;
          border-bottom: 1px solid rgba(221, 221, 221, 0.3);
          width: 0;
          margin-left: 50%;
        }
      }
    }

    &:hover {
      .field-title {
        &:after {
          width: 110%;
          margin-left: -5%;
        }
      }
    }
  }

  .content {
    position: relative;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem 6.5rem 2rem 3rem;

    h3 {
      margin-top: 0;
    }

    position: relative;
    border: 1px solid rgba(221, 221, 221, 0.3);
    width: 100%;
    // padding-right: 5.5rem;

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;

    i {
      &:before {
        content: '\e80d';
        @include fontello();
      }

      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      border-left: 1px solid rgba(221, 221, 221, 0.3);
      padding-left: 2rem;
      padding-right: 2rem;
      color: $wcm-yellow;

      padding-top: 1rem;
      padding-bottom: 1rem;
      // margin-right: .1rem;
    }

  }

  .external-link {
    &::after {
      content: none;
    }
  }
}

.pane-faq-panel-pane-3,
.pane-faq-panel-pane-4,
.pane-faq-panel-pane-5 {
  clear: both;
}

// Patient Stories
.view-patient-stories {
  clear: both;

  .views-row {
    @include clearfix();
    border-bottom: 1px solid $wcm-border-gray;
    margin-bottom: 20px;
    padding-bottom: 20px;

    .views-field-field-featured-image {
      margin-bottom: 10px;

      img {
        width: 100%;
      }

      @include breakpoint($sm) {
        float: left;
        margin-right: 3rem;
        width: 25%;
        max-width: 25%;
      }
    }

    &.views-row-last {
      border-bottom: none;
    }
  }
}

div[class*="pane-patient-stories-panel-pane-"] {
  .pane-title {
    @extend .section-header;
  }
}

.pane-patient-stories-panel-pane-7 {
  .pane-title {
    @extend .section-header;
  }

  .view-patient-stories {
    @include clearfix();

    .view-content {
      margin-left: -20px;
      margin-right: -20px;

      .views-row {
        border-bottom: none;
        padding-bottom: 0;

        .views-field-field-featured-image {
          float: none;
          margin-right: 0;
          max-width: none;
          width: auto;
        }
      }
    }
  }
}

// Glossary
.view-glossary-view {
  .view-content {
    .views-summary {
      padding-left: 0;
      margin-bottom: 20px;

      li {
        display: inline-block;
        padding-right: 0.75rem;
        padding-left: 0.25rem;
        margin: 0;
        border-right: 1px solid $wcm-border-gray;
      }
    }

    .views-row {
      margin-bottom: 20px;
    }
  }
}

//////////////////////

.neurosurgery-image-zoom {
  max-width: 210px;
  margin-bottom: 20px;

  &.image-position-left {
    @include breakpoint($sm) {
      float: left;
      margin-right: 20px;
    }
  }

  &.image-position-right {
    @include breakpoint($sm) {
      float: right;
      margin-left: 20px;
    }
  }

  figure {
    position: relative;

    a {
      position: relative;
      display: block;
    }

    &:hover {
      .cta-icon {
        color: $wcm-white;
        fill: $wcm-white;
      }
    }
  }

  img {
    max-width: 210px !important;
    width: auto !important;
    float: none !important;
    margin: 0 !important;
    margin-bottom: 0px !important;
  }

  .cta-icon {
    width: 30px;
    height: 30px;
    transition: all 0.3s ease 0s;
    position: absolute;
    bottom: 15px;
    right: 10px;
    fill: $wcm-bright-orange;
    color: $wcm-dark-orange;
  }

  figcaption {
    float: none;
    width: auto;
    text-align: left;
    padding-top: 5px;
  }
}

.view-resources {
  .views-row {
    padding-bottom: 5px;
  }
}
