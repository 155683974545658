// Webinars
.pane-webinars-panel-pane-1 {

  .view-webinars {
    .views-row {
      @include clearfix();
      border-bottom: 1px solid $wcm-border-gray;
      padding-bottom: 20px;

      .views-field-field-featured-image {
        margin-bottom: 1rem;

        img {
          width: 100%;
        }

        @include breakpoint($sm) {
          float: right;
          margin-left: 1rem;

          img {
            max-width: 220px;
          }
        }
      }

      .views-field-field-webinar-mailing-list {
        display: inline-block;
        margin-right: 20px;
      }

      .views-field-field-webinar-past-episodes {
        display: inline-block;
      }
    }
  }
}
